export default {
  fr: {
    application: {
      name: 'Générateur d\'arbre de tournoi',
      company: 'MonTournoi.net',
      description: 'Outil/Générateur pour réaliser des arbres de tournois à partir d\'une liste de participant.',
      copyright: '© 2022 MonTournoi.net.'
    },
    menu: {
      home: 'Accueil',
      about: 'A propos de'
    },
    tournament: {
      form: {
        name: 'Nom du tournoi',
        date: 'Date du tournoi',
        teams: 'Participants / Equipes (nom, couleur, groupe)',
        team: 'Nom / Equipe',
        pause: 'Nom de la pause',
        total: 'Nombre de match: ',
        parameters: 'Divers',
        group: 'Groupe',
        sport: 'Sport',
        match: {
          duration: 'Durée des matchs (mn)'
        },
        interlude: {
          title: 'Pause',
          duration: 'Durée des pauses (mn)',
          name: 'Intitulé des pauses',
          frequency: 'Fréquence des pauses',
          enable: 'Ajout de pauses'
        },
        interludes: {
          specific: 'Pauses supplémentaires'
        },
        roundtrip: {
          title: 'Aller / Retour',
          enable: 'Match aller / retour'
        },
        finale: {
          title: 'Phase finale',
          enable: 'Ajout d\'une phase finale'
        },
        sameNumberOfMatch: {
          enable: 'Même nombre de match'
        },
        show: {
          group: 'Afficher les groupes'
        },
        tabs: {
          main: 'Détails',
          teams: 'Equipes',
          interludes: 'Pauses',
          finales: 'Phase finale'
        }
      },
      action: {
        generate: 'Générer les matchs',
        paused: 'Ajouter une pause',
        add: 'Ajouter une équipe'
      }
    },
    sports: {
      ICEHOCKEY: {
        name: 'Hockey sur glace',
        code: 'ICEHOCKEY',
        interlude: 'Surfacage'
      },
      BROOMBALL: {
        name: 'Broomball',
        code: 'BROOMBALL',
        interlude: ''
      },
      SOCCER: {
        name: 'Football',
        code: 'SOCCER',
        interlude: 'Mi-temps'
      }
    },
    generator: {
      author: 'Montournoi.net',
      title: {
        default: 'Generateur de matches'
      },
      file: {
        subject: 'Generateur de matches'
      },
      fields: {
        matchs: 'Les matchs',
        teams: 'Les équipes',
        team: {
          title: 'Equipe {0}'
        },
        day: {
          start: 'Ouverture',
          end: 'Fermeture'
        },
        group: {
          show: 'Afficher les groupes'
        }
      },
      match: {
        title: 'Match {0}'
      },
      team: {
        title: '{0} ({1})'
      },
      surfacing: {
        title: 'Surfaçage'
      },
      buttons: {
        excel: {
          export: 'Export excel'
        },
        json: {
          export: 'Export json'
        }
      },
      style: {
        saturation: 'Nuance des couleurs'
      },
      excel: {
        column1: 'Heure',
        column2: 'Local',
        column3: '',
        column4: 'Visiteur',
        column5: 'Groupe'
      },
      info: {
        matches: {
          number: 'Nombre de match',
          invisible: 'Nombre de match non renseignés'
        }
      },
      modes: {
        title: 'Mode de génération',
        FOLLOW: 'Aucune stratégie',
        RANDOM: 'Mode aléatoire',
        SORTED: 'Mode organisé'
      }
    }
  }
}
