<template>
  <div>
    <header>
      <div class="page-home page-header min-vh-100 min-height-200" loading="lazy">
        <span class="mask bg-gradient-dark opacity-5"></span>
        <div class="container">
          <div class="row">
            <div class="col-1">
              <img src="../assets/img/logos/montournoi.png" width="64" height="64">
            </div>
            <div class="col-8 d-flex justify-content-center flex-column">
              <h1 class="text-white">{{$t('application.name')}}</h1>
              <p class="text-white opacity-8 lead">{{$t('application.description')}}</p>
            </div>
          </div>
        </div>
      </div >
    </header>
    <section class="my-3 py-3">
      <div class="container">
        <div class="row align-items-start">
          <div class="col-4 me-auto mt-0 mt-4">
            <div class="card card-background-mask-primary shadow-primary">
              <div class="card-body">
                <div class="tabs">
                  <a v-on:click="activetab=1" v-bind:class="[ activetab === 1 ? 'active' : '' ]">{{$t('tournament.form.tabs.main')}}</a>
                  <a v-on:click="activetab=2" v-bind:class="[ activetab === 2 ? 'active' : '' ]">{{$t('tournament.form.tabs.teams')}}</a>
                  <a v-on:click="activetab=3" v-bind:class="[ activetab === 3 ? 'active' : '' ]">{{$t('tournament.form.tabs.interludes')}}</a>
                  <a v-on:click="activetab=4" v-bind:class="[ activetab === 4 ? 'active' : '' ]">{{$t('tournament.form.tabs.finales')}}</a>
                </div>
                <div class="content">
                  <div v-if="activetab === 1" class="tabcontent container">
                    <div class="row">
                      <label class="form-label">{{$t('tournament.form.name')}}</label>
                      <div class="col-11">
                        <div class="input-group input-group-outline m-1"  :class="nameClass">
                          <input type="text" v-model="name" v-on:blur="update()"  class="form-control" :placeholder="$t('tournament.form.name')" :aria-label="$t('tournament.form.name')" aria-describedby="basic-name">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <label class="form-label">{{$t('tournament.form.date')}}</label>
                      <div class="row">
                        <div class="input-group input-group-outline m-1">
                          <div class="col-6 pe-1">
                            <v-datetime v-model="beginDate" format="DD/MM/YYYY H:i" width="100%" input-class="form-control date-time"></v-datetime>
                          </div>
                          <div class="col-6 ps-1">
                            <v-datetime v-model="endDate" format="DD/MM/YYYY H:i" width="100%" input-class="form-control date-time"></v-datetime>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <label class="form-label">{{$t('tournament.form.match.duration')}}</label>
                      <div class="row">
                        <div class="input-group input-group-outline my-1" :class="matchDurationClass">
                          <div class="col-12">
                            <input type="number" v-model="matchDuration" class="form-control" :placeholder="$t('tournament.form.match.duration')" :aria-label="$t('tournament.form.match.duration')" aria-describedby="basic-name">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <label class="form-label">{{$t('tournament.form.sport')}}</label>
                      <div class="row">
                        <div class="input-group input-group-outline my-1"  :class="sportClass">
                          <div class="col-12">
                            <select class="form-control" id="selectedSport" v-model="matchSport">
                              <option v-for="sport in sports" :value="sport.code" :key="sport.code" name="item.sport">
                                {{sport.name}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row pt-2">
                      <div class="col-6">
                        <label>{{$t('tournament.form.roundtrip.enable')}}</label>
                      </div>
                      <div class="col-6">
                        <div class="form-check form-switch my-1 float-end pe-3">
                          <input class="form-check-input" v-model="roundtripEnable" type="checkbox">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="activetab === 2" class="tabcontent container">
                    <div class="row pt-2">
                      <div class="col-6">
                        <label>{{$t('tournament.form.show.group')}}</label>
                      </div>
                      <div class="col-6">
                        <div class="form-check form-switch my-1 float-end pe-3">
                          <input class="form-check-input" v-model="showGroup" type="checkbox">
                        </div>
                      </div>
                    </div>
                    <div class="row pt-2">
                      <label class="form-label">{{$t('tournament.form.teams')}}</label>
                      <div class="row">
                        <button class="btn btn-icon btn-2 btn-success" type="button" v-on:click="addTeam">
                          <span class="btn-inner--icon"><i class="ni ni-fat-add"></i>{{$t('tournament.action.add')}}</span>
                        </button>
                      </div>
                      <div class="row" v-for="(team, index) in teams" :key="index">
                        <div class="col-4">
                          <div class="input-group input-group-outline">
                            <input type="text" v-model="team.name"  v-on:blur="update()" class="form-control" :placeholder="$t('tournament.form.team')" :aria-label="$t('tournament.form.team')" aria-describedby="basic-team">
                          </div>
                        </div>
                        <div class="col-2">
                          <div class="input-group input-group-outline">
                            <v-swatches v-model="team.color" show-border shapes="squares"  swatches="text-advanced" show-fallback fallback-input-type="color" v-on="listeners"/>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="input-group input-group-outline">
                            <input type="text" v-model="team.group" v-on:blur="update()" class="form-control" :placeholder="$t('tournament.form.group')" :aria-label="$t('tournament.form.group')" aria-describedby="basic-group">
                          </div>
                        </div>
                        <div class="col-2 team-button">
                          <div class="input-group input-group-outline">
                            <button class="btn btn-icon-only btn-3 btn-warning" type="button"  v-on:click="removeTeam(index)">
                              <span class="btn-inner--icon"><i class="ni ni-fat-delete"></i></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="activetab === 3" class="tabcontent container">
                    <div class="row pt-2">
                      <div class="col-6">
                        <label>{{$t('tournament.form.interlude.enable')}}</label>
                      </div>
                      <div class="col-6">
                        <div class="form-check form-switch my-1 float-end pe-3">
                          <input class="form-check-input" v-model="interludeEnable" type="checkbox">
                        </div>
                      </div>
                    </div>
                    <div class="row pt-2" v-if="interludeEnable">
                      <label class="form-label">{{$t('tournament.form.interlude.frequency')}}</label>
                      <div class="row">
                        <div class="input-group input-group-outline my-1" :class="interludeFrequencyClass">
                          <div class="col-12">
                            <input type="number" v-model="interludeFrequency" class="form-control" :placeholder="$t('tournament.form.interlude.frequency')" :aria-label="$t('tournament.form.interlude.frequency')" aria-describedby="basic-name">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="interludeEnable">
                      <label class="form-label">{{$t('tournament.form.interlude.duration')}}</label>
                      <div class="row">
                        <div class="input-group input-group-outline my-1" :class="interludeDurationClass">
                          <div class="col-12">
                            <input type="number" v-model="interludeDuration" class="form-control" :placeholder="$t('tournament.form.interlude.duration')" :aria-label="$t('tournament.form.interlude.duration')" aria-describedby="basic-name">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="interludeEnable">
                      <label class="form-label">{{$t('tournament.form.interlude.name')}}</label>
                      <div class="row">
                        <div class="input-group input-group-outline my-1">
                          <div class="col-12">
                            <input type="string" v-model="interludeName" class="form-control" :placeholder="$t('tournament.form.interlude.name')" :aria-label="$t('tournament.form.interlude.name')" aria-describedby="basic-name">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="activetab === 4" class="tabcontent container">
                    <div class="row pt-2">
                      <div class="col-9">
                        <label>{{$t('tournament.form.finale.enable')}}</label>
                      </div>
                      <div class="col-3">
                        <div class="form-check form-switch my-1 float-end pe-3">
                          <input class="form-check-input" v-model="finaleEnable" type="checkbox">
                        </div>
                      </div>
                    </div>
                    <div class="row pt-2" v-if="finaleEnable">
                      <div class="col-9">
                        <label>{{$t('tournament.form.sameNumberOfMatch.enable')}}</label>
                      </div>
                      <div class="col-3">
                        <div class="form-check form-switch my-1 float-end pe-3">
                          <input class="form-check-input" v-model="sameNumberOfMatch" type="checkbox">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-8 min-height-300 d-flex flex-wrap justify-content-center align-items-center" v-if="loading">
            <div class="text-center">
              <vue-loaders-ball-spin-fade-loader color="#fb8c00" scale="2"/>
            </div>
          </div>
          <div class="col-8 ms-auto" v-if="result && !loading">
            <div class="row">
              <div class="col-6">
                <h3 class="font-weight-bolder mb-4">{{result.name}}</h3>
                <h6 class="font-weight-bolder mb-4">{{$t('tournament.form.total')}}<span class="match-total">{{totalMatch}}</span></h6>
              </div>
              <div class="col-6 row">
                <div class="col-6" v-if="exportable()">
                  <button class="btn btn-icon btn-2 btn-info float-end"  v-on:click="exportJson">{{$t('generator.buttons.json.export')}}</button>
                </div>
                <download-excel
                  class = "col-6"
                  :groups = "showGroup"
                  :days = "days"
                  :title="name"
                  :name="filename"
                  v-if="exportable()">
                  <button class="btn btn-icon btn-2 btn-info float-end">{{$t('generator.buttons.excel.export')}}</button>
                </download-excel>
              </div>
            </div>
            <div v-for="(phase, p) in result.phases" :key="p" class="row">
              <i class="material-icons text-gradient text-primary text-3xl">content_copy</i>
              <h4 class="font-weight-bolder">{{phase.name}}</h4>
              <div v-for="(group, g) in phase.groups" :key="g" class="row">
                <h5 class="font-weight-bolder">{{group.name}}</h5>
                <div v-for="(match, m) in group.matches" :key="m" class="col-3 p-2 match-container">
                  <div class="card">
                    <div class="match-title">
                      <span class="mb-2 text-center">{{tooltiph(match)}}</span>
                    </div>
                    <div class="card-body">
                      <ul class="list-group btn-tooltip match-teams" v-if="match.receiver && match.visitor">
                        <li class="list-group-item"  :style="style(match.receiver)">
                          {{teamName(match.receiver)}}
                        </li>
                        <li class="list-group-item"  :style="style(match.visitor)">
                          {{teamName(match.visitor)}}
                        </li>
                      </ul>
                      <ul class="list-group btn-tooltip match-teams" v-else>
                        <li class="list-group-item"  :style="style(match.receiver)">
                          {{match.name}}
                        </li>
                        <li class="list-group-item"  :style="style(match.visitor)">
                          <br/>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { ENTRYPOINT, ENVIRONNEMENT } from '../config/entrypoint'
import VSwatches from 'vue-swatches'
import datetime from '../components/DatetimePicker'
import Vue from 'vue'
import 'vue-swatches/dist/vue-swatches.css'
import VsLoader from '@vuesimple/vs-loader'
import 'vue-loaders/dist/vue-loaders.css'
import VueLoaders from 'vue-loaders'
import * as moment from 'moment'
import JsonExcel from '../components/JSonExcel'
import generator from '../utils/generator'
import { saveAs } from 'file-saver'

Vue.use(VueLoaders)

Vue.component('v-loader', VsLoader)
Vue.component('v-swatches', VSwatches)
Vue.component('v-datetime', datetime)
Vue.component('download-excel', JsonExcel)

export default {
  data: function () {
    return {
      name: '',
      loading: false,
      teams: [],
      days: [],
      sports: [],
      endDate: null,
      beginDate: null,
      result: null,
      format: 'DD/MM/YYYY HH:mm',
      filenameFormat: 'yyyy',
      callFormat: 'YYYY/MM/DD HH:mm',
      dayFormat: 'YYYY-MM-DD',
      matchDuration: 40,
      matchSport: '',
      interludeDuration: 15,
      interludeFrequency: 3,
      interludeEnable: false,
      roundtripEnable: false,
      finaleEnable: false,
      sameNumberOfMatch: false,
      showGroup: false,
      interludeName: '',
      totalMatch: 0,
      activetab: 1
    }
  },
  mounted: function () {
    moment.locale('fr')
    this.beginDate = moment().set('hour', 8).set('minute', 0).format(this.format)
    this.endDate = moment().set('hour', 18).set('minute', 0).format(this.format)
    console.log(moment())
    this.sports = this.$t('sports')
    if (ENVIRONNEMENT === 'dev') {
      this.teams = [
        /*
        { name: 'Caen Rouge', group: '', color: '#FF0000' },
        { name: 'Caen Bleu', group: '', color: '#0000FF' },
        { name: 'Mantes', group: '', color: '#00FF99' },
        { name: 'Les Zebulons', group: '', color: '#999999' },
        { name: 'Amiens', group: '', color: '#111111' },
        { name: 'Tours', group: '', color: '#9900FF' },
        { name: 'Charleroi', group: '', color: '#FF9900' }
        */
        /*
        { name: 'Caen', group: 'Normandie', color: '#FFFFFF' },
        { name: 'Rouen', group: 'Normandie', color: '#00FF00' },
        { name: 'Louviers', group: 'Normandie', color: '#0000FF' },
        { name: 'Le Havre', group: 'Normandie', color: '#FFFF00' },
        { name: 'Cherbourg', group: 'Normandie', color: '#FF5500' },
        { name: 'Avranche', group: 'Normandie', color: '#FF0055' },
        { name: 'Amiens', group: 'Haut de france', color: '#FF00FF' },
        { name: 'Wasquehal', group: 'Haut de france', color: '#00FFFF' },
        { name: 'Compiègne', group: 'Haut de france', color: '#FFF000' },
        { name: 'Lille', group: 'Haut de france', color: '#000FFF' },
        { name: 'Dunkerque', group: 'Haut de france', color: '#0FFFF0' },
        { name: 'Cergie', group: 'Haut de france', color: '#550055' },
        { name: 'Evry', group: 'IDF', color: '#338899' },
        { name: 'Very', group: 'IDF', color: '#883399' },
        { name: 'Neuilly', group: 'IDF', color: '#993388' },
        { name: 'Français vollant', group: 'IDF', color: '#889933' }
        */
        { name: 'Caen', group: 'Normandie', color: '#FFFFFF' },
        { name: 'Rouen', group: 'Normandie', color: '#00FF00' },
        { name: 'Louviers', group: 'Normandie', color: '#0000FF' },
        { name: 'Le Havre', group: 'Normandie', color: '#FFFF00' },
        { name: 'Cherbourg', group: 'Normandie', color: '#FF5500' },
        { name: 'Amiens', group: 'Haut de france', color: '#FF00FF' },
        { name: 'Wasquehal', group: 'Haut de france', color: '#00FFFF' },
        { name: 'Compiègne', group: 'Haut de france', color: '#FFF000' },
        { name: 'Dunkerque', group: 'Haut de france', color: '#0FFFF0' },
        { name: 'Cergie', group: 'Haut de france', color: '#550055' }
      ]
      this.beginDate = moment('04/06/2022', 'DD/MM/YYYY').set('hour', 9).set('minute', 45).format(this.format)
      this.endDate = moment('06/06/2022', 'DD/MM/YYYY').set('hour', 20).set('minute', 0).format(this.format)
      this.interludeEnable = true
      this.finaleEnable = false
      this.showGroup = false
      this.interludeName = 'Surfaçage'
      this.name = 'Tournoi de Caen Loisir'
      this.roundtripEnable = false
      this.interludeFrequency = 2
      this.matchDuration = 30
    } else {
      this.teams = []
    }
  },
  watch: {
    interludeDuration () {
      this.update()
    },
    interludeFrequency () {
      this.update()
    },
    interludeName () {
      this.update()
    },
    interludeEnable () {
      this.update()
    },
    roundtripEnable () {
      this.update()
    },
    finaleEnable () {
      this.update()
    },
    sameNumberOfMatch () {
      this.update()
    },
    matchDuration () {
      this.update()
    },
    beginDate () {
      this.update()
    },
    endDate () {
      this.update()
    }
  },
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: this.updateColorValue
      }
    },
    nameClass () {
      return this.name === null || this.name === '' ? 'is-invalid' : 'is-valid'
    },
    sportClass () {
      return this.matchSport === null || this.matchSport === '' ? 'is-invalid' : 'is-valid'
    },
    matchDurationClass () {
      return this.nonNullValueClass(this.matchDuration)
    },
    interludeDurationClass () {
      return this.nonNullValueClass(this.interludeDuration)
    },
    interludeFrequencyClass () {
      return this.nonNullValueClass(this.interludeFrequency)
    }
  },
  methods: {
    nonNullValueClass (value) {
      return value === null || value === '' || value === 0 ? 'is-invalid' : 'is-valid'
    },
    update: function () {
      this.generate()
    },
    tooltip (match) {
      const date = moment.utc(match.date, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').format(this.format)
      return match.receiver.name + ' vs ' + match.visitor.name + '\r\n' + date
    },
    tooltiph (match) {
      const date = moment.utc(match.date, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').format(this.format)
      return date
    },
    updateColorValue (event) {
      this.$emit('input', event)
      this.generate()
    },
    addTeam: function () {
      const team = { name: '', group: '' }
      this.teams.push(team)
    },
    removeTeam: function (index) {
      this.teams.splice(index, 1)
      this.generate()
    },
    teamName: function (team) {
      if (team && team.name) {
        return team.name
      }
      return ''
    },
    exportJson: function () {
      var exported = Object.assign({}, this.result)
      exported.beginDate = this.beginDate
      exported.endDate = this.endDate
      exported.sport = this.matchSport
      for (const phaseKey in exported.phases) {
        const phase = exported.phases[phaseKey]
        for (const groupKey in phase.groups) {
          const group = phase.groups[groupKey]
          for (const matchKey in group.matches) {
            const match = group.matches[matchKey]
            match.date = this.tooltiph(match)
          }
        }
      }
      saveAs(new Blob([JSON.stringify(exported)], { type: 'text/json' }), this.filename('json'))
    },
    filename (extension) {
      var name = 'tournament'
      if (this.name !== null && this.name.length > 0) {
        name = this.name.toLowerCase().replace(' ', '-')
      }
      if (this.beginDate !== null) {
        var date = moment(this.beginDate, this.format).format(this.filenameFormat)
        name = name + '-' + date
      }
      return name + '.' + extension
    },
    disabled: function (index) {
      return index !== this.teams.length - 1 ? undefined : 'disabled'
    },
    exportable: function () {
      return this.result && !this.loading && this.totalMatch > 0
    },
    style: function (team) {
      const font = team !== null ? this.font(team.color) : ''
      return 'background:' + (team !== null ? team.color : 'AAAAAA') + 'AA; color:' + font
    },
    font: function (hex) {
      return generator.font(hex)
    },
    generate: function () {
      this.loading = true
      const that = this
      const credentials = {
        username: 'anonymous@extrem-api.com',
        password: 'anonymous'
      }
      // eslint-disable-next-line no-undef
      that.axios.post(ENTRYPOINT + '/api/signin', credentials)
        .then(function (response) {
          const token = response.data.token
          const config = {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
          const type = that.roundtripEnable ? 'FORMAT_GROUP_ROUNDTRIP' : 'FORMAT_GROUP'
          const endDate = moment(that.endDate, that.format).format(that.callFormat)
          const beginDate = moment(that.beginDate, that.format).format(that.callFormat)
          const parameters = {
            name: that.name,
            endDate: endDate,
            beginDate: beginDate,
            matchDuration: that.matchDuration,
            interludeDuration: that.interludeEnable ? that.interludeDuration : 0,
            interludeFrequency: that.interludeEnable ? that.interludeFrequency : 0,
            type: type,
            finale: that.finaleEnable,
            sameNumberOfMatch: that.sameNumberOfMatch,
            teams: that.teams.filter(value => value.name !== '')
          }
          that.axios.post(ENTRYPOINT + '/api/championships/generate', parameters, config)
            .then(function (response) {
              that.generator(response.data)
            })
            .catch(function (error) {
              console.log(error)
              that.loading = false
            })
        })
        .catch(function (error) {
          console.log(error)
          that.loading = false
        })
    },
    generator: function (data) {
      this.days = []
      this.totalMatch = 0
      this.loading = false
      this.result = data
      generator.generate(this)
      generator.sort(this)
    }
  }
}
</script>
