import * as moment from 'moment'

export default {
  sort: function (that) {
    for (const dayKey in that.days) {
      const day = that.days[dayKey]
      day.matchs = day.matchs.sort(function (a, b) {
        const aDate = moment(a.date)
        const bDate = moment(b.date)
        return aDate - bDate
      })
    }
  },
  generate: function (that) {
    this.generatePhases(that)
    this.generateInterludes(that)
  },
  generateInterludes: function (that) {
    for (const interludeKey in that.result.interludes) {
      const interlude = that.result.interludes[interludeKey]
      const day = this.targetDay(interlude, that)
      interlude.type = 'INTERLUDE'
      interlude.name = that.interludeName
      that.days[day].matchs.push(interlude)
    }
  },
  generatePhases: function (that) {
    for (const phaseKey in that.result.phases) {
      const phase = that.result.phases[phaseKey]
      for (const groupKey in phase.groups) {
        const group = phase.groups[groupKey]
        for (const matchKey in group.matches) {
          const match = group.matches[matchKey]
          const day = this.targetDay(match, that)
          const value = this.generateMatch(match)
          value.type = 'MATCH'
          that.days[day].matchs.push(value)
          that.totalMatch++
        }
      }
    }
  },
  generateMatch: function (match) {
    return {
      group: match.group !== null && match.group.name !== null ? match.group.name : '',
      date: match.date,
      name: match.name,
      receiver: {
        name: match.receiver !== null ? match.receiver.name : '',
        color: match.receiver !== null ? match.receiver.color : '',
        font: match.receiver !== null ? this.font(match.receiver.color) : ''
      },
      visitor: {
        name: match.visitor !== null ? match.visitor.name : '',
        color: match.visitor !== null ? match.visitor.color : '',
        font: match.visitor !== null ? this.font(match.visitor.color) : ''
      }
    }
  },
  targetDay: function (match, that) {
    const day = moment.utc(match.date, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').format(that.dayFormat)
    if (typeof that.days[day] === 'undefined') {
      that.days[day] = {
        date: day,
        matchs: []
      }
    }
    return day
  },
  font: function (hex) {
    const rgb = this.hexToRgb(hex)
    if (rgb !== null) {
      const average = (rgb.b + rgb.g + rgb.r) / 3
      const etype = Math.sqrt((rgb.b - average) * (rgb.b - average) + (rgb.g - average) * (rgb.g - average) + (rgb.r - average) * (rgb.r - average)) / 3
      if (rgb.g > rgb.b && rgb.g > rgb.r && etype > 65) {
        return '#000000'
      }
      return average < (255 / 2) ? '#FFFFFF' : '#000000'
    }
    return '#000000'
  },
  hexToRgb: function (hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null
  }
}
