<template>
  <div
    :id="id"
    @click="generate">
    <slot>
      {{button}}
    </slot>
  </div>
</template>

<script>

/*
    sheet['A1'].s = {
    fill: {
      patternType: "none", // none / solid
      fgColor: { rgb: "FF000000" },
      bgColor: { rgb: "FFFFFFFF" }
    },
    font: {
      name: 'Times New Roman',
      sz: 16,
      color: { rgb: "#FF000000" },
      bold: true,
      italic: false,
      underline: false
    },
    border: {
      top: { style: "thin", color: { auto: 1 } },
      right: { style: "thin", color: { auto: 1 } },
      bottom: { style: "thin", color: { auto: 1 } },
      left: { style: "thin", color: { auto: 1 } }
    },
    alignment: { horizontal: "center" }
  }
   */

import XLSX from 'xlsx'
import * as moment from 'moment'
import { saveAs } from 'file-saver'
import XLSTYLE from 'xlsx-style-correct'

export default {
  data: function () {
    return {
      sheetFormat: 'DD-MM-YYYY',
      matchFormat: 'HH:mm',
      font: {
        size: {
          normal: 20,
          title: 24
        }
      },
      blackColor: null,
      whiteColor: null
    }
  },
  props: {
    button: {
      type: String,
      default: 'Download Excel'
    },
    days: {
      type: Array,
      required: true
    },
    name: {
      type: Function,
      default: function (extension) {
        return 'data.' + extension
      }
    },
    title: {
      type: String,
      default: ''
    },
    meta: {
      type: Array,
      default: () => []
    },
    groups: {
      type: Boolean,
      default: () => true
    }
  },
  created: function () {
    const black = [0, 0, 0]
    this.blackFont = this.toHex(black[0], black[1], black[2])
    const white = [255, 255, 255]
    this.whiteColor = this.toHex(white[0], white[1], white[2])
  },
  computed: {
    id: function () {
      return new Date().getTime()
    }
  },
  methods: {
    generate () {
      const header = []
      const type = 'binary'
      const bookType = 'xlsx'
      var workBook = XLSX.utils.book_new()
      const cols = [{ wch: 16 }, { wch: 30 }, { wch: 8 }, { wch: 30 }, { wch: 30 }]
      workBook.Props = {
        Title: this.title,
        Subject: this.$t('generator.file.subject'),
        Author: this.$t('generator.author'),
        CreatedDate: new Date(2017, 12, 19)
      }
      header.push(this.$t('generator.excel.column1'))
      header.push(this.$t('generator.excel.column2'))
      header.push(this.$t('generator.excel.column3'))
      header.push(this.$t('generator.excel.column4'))
      if (this.groups) {
        header.push(this.$t('generator.excel.column5'))
      }
      for (const dayKey in this.days) {
        const workSheetData = []
        const day = this.days[dayKey]
        const workSheetTitle = moment(day.date).format(this.sheetFormat)
        const merges = []
        workSheetData.push(header)
        let index = 1
        for (const matchKey in day.matchs) {
          const match = day.matchs[matchKey]
          const matchDate = moment(match.date).utc().format(this.matchFormat)
          const cellHour = this.generateCell(matchDate, this.whiteColor, this.blackColor, true)
          const cellEmpty = this.generateCell('', this.whiteColor, this.blackColor, true)
          const cellEmptyBorder = this.generateCell('', this.whiteColor, this.blackColor, true, true, true)
          if (match.type === 'MATCH') {
            if (match.receiver && match.visitor && match.receiver.name !== '' && match.visitor.name !== '') {
              const cellReceiver = this.generateCell(match.receiver.name, match.receiver.color, match.receiver.font, false)
              const cellVisitor = this.generateCell(match.visitor.name, match.visitor.color, match.visitor.font, false)
              if (this.groups) {
                const cellGroup = this.generateCell(match.group, this.whiteColor, this.blackColor, false)
                workSheetData.push([cellHour, cellReceiver, cellEmpty, cellVisitor, cellGroup])
              } else {
                workSheetData.push([cellHour, cellReceiver, cellEmpty, cellVisitor])
              }
            } else {
              if (match && match.name) {
                const cellMatch = this.generateCell(match.name !== null ? match.name : '', this.whiteColor, this.blackColor, false, true, true)
                workSheetData.push([cellHour, cellMatch, cellEmptyBorder, cellEmptyBorder, this.groups ? cellEmptyBorder : cellEmpty])
                merges.push({ s: { r: index, c: 1 }, e: { r: index, c: this.groups ? 4 : 3 } })
              }
            }
          } else if (match.type === 'INTERLUDE') {
            const cellInterlude = this.generateCell(match.name !== null ? match.name : '', this.whiteColor, this.blackColor, true, true, true)
            workSheetData.push([cellHour, cellInterlude, cellEmptyBorder, cellEmptyBorder, this.groups ? cellEmptyBorder : cellEmpty])
            merges.push({ s: { r: index, c: 1 }, e: { r: index, c: this.groups ? 4 : 3 } })
          }
          index++
        }
        const workSheet = XLSX.utils.aoa_to_sheet(workSheetData)
        workSheet['!cols'] = cols
        workSheet['!merges'] = merges
        this.headers(workSheet, ['A1', 'B1', 'C1', 'D1', 'E1'], this.whiteColor, this.blackColor)
        XLSX.utils.book_append_sheet(workBook, workSheet, workSheetTitle)
      }
      const workBookOut = XLSTYLE.write(workBook, { bookType: bookType, bookSST: true, type: type, cellStyles: true, raw: true })
      saveAs(new Blob([this.s2ab(workBookOut)], { type: 'application/octet-stream' }), this.filename())
    },
    headers (sheet, columns, whiteColor, blackFont) {
      for (const key in columns) {
        if (typeof columns[key] !== 'undefined' && typeof sheet[columns[key]] !== 'undefined') {
          sheet[columns[key]].s = this.header(whiteColor, blackFont)
        }
      }
    },
    toHex (red, green, blue) {
      const rgb = (red << 16) | (green << 8) | (blue << 0)
      return 'FF' + (0x1000000 + rgb).toString(16).slice(1)
    },
    toCss (rgb) {
      return 'rgb(' + rgb[0] + ', ' + rgb[1] + ', ' + rgb[2] + ')'
    },
    toColor (hex, other) {
      if (hex != null && hex.length > 0) {
        return hex.substring(1)
      }
      return other
    },
    header (whiteColor, blackFont) {
      return {
        fill: { fgColor: { rgb: whiteColor } },
        font: { color: { rgb: blackFont }, sz: this.font.size.title },
        alignment: { horizontal: 'center' }
      }
    },
    s2ab (s) {
      const buffer = new ArrayBuffer(s.length)
      const view = new Uint8Array(buffer)
      for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xFF
      }
      return buffer
    },
    filename () {
      return this.name('xlsx')
    },
    generateCell (value, color, font, title, center, border) {
      const alignment = center ? 'center' : 'left'
      return {
        v: value,
        s: {
          fill: {
            fgColor: {
              rgb: this.toColor(color, this.whiteColor)
            }
          },
          font: {
            color: {
              rgb: this.toColor(font, this.blackColblackColoror)
            },
            sz: title ? this.font.size.title : this.font.size.normal,
            bold: !!title
          },
          border: border ? {
            top: { style: 'thin', color: { auto: 2 } },
            right: { style: 'thin', color: { auto: 2 } },
            bottom: { style: 'thin', color: { auto: 2 } },
            left: { style: 'thin', color: { auto: 2 } }
          } : {},
          alignment: {
            horizontal: alignment
          }
        }
      }
    }
  }
}
</script>
