import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import axios from 'axios'
import Vuex from 'vuex'
import VueAxios from 'vue-axios'

import './assets/css/nucleo-icons.css'
import './assets/css/nucleo-svg.css'
import './assets/css/material-kit.css'

import './scss/_common.scss'

import Nav from '@/components/Nav.vue'

Vue.config.productionTip = false

Vue.component('nav-bar', Nav)

Vue.use(Vuex)
Vue.use(VueAxios, axios)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
