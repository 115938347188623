<template>
  <div id="app">
    <div>
      <!-- nav-bar/ -->
      <router-view/>
    </div>
  </div>
</template>
<script>
export default {
}
</script>
