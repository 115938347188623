<template>
  <nav class="navbar navbar-expand-lg navbar-dark navbar-absolute bg-transparent shadow-none">
    <div class="container">
      <a class="navbar-brand text-white" href="/">
        <img src="../assets/img/logos/montournoi.png" width="32" height="32">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-header-2" aria-controls="navbar-header-2" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbar-header-2">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item">
            <a class="nav-link text-white" href="javascript:;">
              <router-link to="/" class="nav-link text-white">{{$t('menu.home')}}</router-link>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" href="javascript:;">
              <router-link to="/about" class="nav-link text-white">{{$t('menu.about')}}</router-link>
            </a>
          </li>
        </ul>
        <ul class="nav navbar-nav">
          <li class="nav-item">
            <a class="nav-link text-white" href="https://twitter.com/CreativeTim">
              <i class="fab fa-twitter"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white mx-2" href="https://www.facebook.com/CreativeTim">
              <i class="fab fa-facebook"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" href="https://www.instagram.com/CreativeTimOfficial">
              <i class="fab fa-instagram"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'Nav'
}
</script>
